import PropTypes from "prop-types"
import Chart from "react-google-charts"
import { customerDbhSettings } from "../../helpers/customerSettings";

export default function ForestDetailsHistogram(props) {
  
  const maxNumBuckets = Math.floor(props.max/props.bucketSize)
  return (
    <div id={props.id} style={{margin: "1em", display: "flex", flexDirection: "column", border: "3px outset #0f5132", width: "90%", minWidth: "300px"}}>
      <Chart
        chartType="Histogram"
        loader={"Loading"}
        data={props.data}
        getChartWrapper={(chartWrapper) => {
          props.setState(props.id, chartWrapper)
        }}
        options={{
          height: "100%",
          width: "100%",
          title: props.title,
          titleTextStyle: {
            color: "#d7d6cb"
          },
          legend: "none",
          colors: ["#029970"],
          vAxis: {
            textStyle: {
              color:"#d7d6cb"
            },
            title: "Number of trees", 
            titleTextStyle: {
              color: "#d7d6cb"
          }},
          hAxis: {
            format: 'decimal',
            textStyle: {
              color:"#d7d6cb"
            },
            slantedText: false,
            title: props.xLabel, 
            titleTextStyle: {
              color: "#d7d6cb"
          }},
          backgroundColor: "#212529",
          is3D: true,
          bar: { gap: 1 },
          histogram: {
            bucketSize: props.histogramSettings.bucketSize,
            lastBucketPercentile: 4,
            hideBucketItems: true,
          },
        }}
        
      />
    </div>
  );
}

ForestDetailsHistogram.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  xLabel: PropTypes.string.isRequired,
  histogramSettings: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired
}