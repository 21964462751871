import React from 'react';
import { Card} from 'react-bootstrap';
import PropTypes from 'prop-types'

const DroneStatus = ({name}) => {
    const droneData = [
        // { field: 'Drone number', value: '0005 of 5145' },
        { field: 'Drone pilot', value: 'Pramod (Id – 24335)' },
        { field: 'Average pilot productivity', value: '15ha per day' },
        { field: 'Drone status', value: 'Currently undertaking pesticide spraying' },
        { field: 'Chemical used', value: 'Fungicide (Id – 33425)' },
        { field: 'Amount used', value: '6 litres' },
        { field: 'Amount remaining', value: '19 litres' },
        { field: 'Spray area', value: '3ha' },
        { field: 'Crop type', value: 'Legume' },
        { field: 'GPS location', value: '37.7749° N, 122.4194° W'  },
        { field: 'Flight time conducted', value: '10min' },
        { field: 'Flight time remaining', value: '25min' },
        { field: 'Average flights per day', value: '6' },
        { field: 'Regulator', value: 'Madhya Pradesh Province (Id – 55483)' }
      ];
    
    // const droneData = [
    //     { field: 'Battery Level', value: '85%' },
    //     { field: 'GPS Signal', value: 'Strong' },
    //     { field: 'Altitude', value: '150 meters' },
    //     { field: 'Speed', value: '20 km/h' },
    //     { field: 'Camera Status', value: 'Active (Recording)' },
    //     { field: 'Last Maintenance', value: '2024-06-15' },
    //     { field: 'Temperature', value: '22°C' },
    //     { field: 'Wind Speed', value: '5 km/h' },
    //     { field: 'Flight Mode', value: 'Autonomous' },
    //     { field: 'Distance Traveled', value: '3.2 km' },
    //     { field: 'Area covered', value: '5.3 ha' },
    //     { field: 'GPS Coordinates', value: '37.7749° N, 122.4194° W' }
    //   ];
    
    // Split the data into two columns
    const middleIndex = Math.ceil(droneData.length / 2);
    const firstColumnData = droneData.slice(0, middleIndex);
    const secondColumnData = droneData.slice(middleIndex);

    const tableStyle = {
        width: '100%',
        textAlign: 'left',
        fontSize: '1em'
    };

    const cellStyle = {
     padding: '5px 10px'
    };

    const columnStyle = {
        verticalAlign: 'top',
        padding: '5px'
    };
  return (
    <div style={{
        display: "flex",
        width:"100%",
      }}>
        <Card 
            bg={"dark"}
            key={"AdminSelectedItem"}
            text={"white"}
            style={{minWidth: "95%", margin: "1em", border: "3px outset #0f5132"}}
            className="mb-3">
            <Card.Header style={{fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}>Drone number: 0005 of 5145 </Card.Header>
            <Card.Body>
            <table style={tableStyle}>
                <tbody>
                <tr>
                    <td style={columnStyle}>
                    <table>
                        <tbody>
                        {firstColumnData.map((data, index) => (
                            <tr key={index}>
                            <td style={cellStyle}><strong>{data.field}</strong></td>
                            <td style={cellStyle}>{data.value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </td>
                    <td style={columnStyle}>
                    <table>
                        <tbody>
                        {secondColumnData.map((data, index) => (
                            <tr key={index}>
                            <td style={cellStyle}><strong>{data.field}</strong></td>
                            <td style={cellStyle}>{data.value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </td>
                </tr>
                </tbody>
            </table>
            </Card.Body>
        </Card>
    </div>
  );
};

export default DroneStatus;