import React, {useState,useEffect,useRef}  from 'react';
import { Button, Card,Modal, Form, ListGroup, CloseButton, Container, Row, Col,   } from 'react-bootstrap';
import PropTypes from 'prop-types'
import axios from "axios";
import useToken from '../../../../App/useToken';
import './CustomersInfo.css';
import { Access, getAccessString } from '../../../../Helper/Helper';

export default function SelectedCustomerInfo(props) {
    const {token} = useToken()
    const [editUsers, setEditUsers] = useState(false)
    const [view, setView] = useState(null)
    const [drones, setDrones] = useState([])
    const [users, setusers] = useState([])
    const [customer, setCustomer] = useState('')
    const [newUserName, setNewUserName] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [userToRemove, setUserToRemove] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editClearanceMode, setEditClearanceMode] = useState(false);
    const [clearances, setClearances] = useState({});
    const [currentClearances, setCurrentClearances] = useState("");
    const setDronesAndUsers = (drones, users) => {
        setDrones(drones)
        setusers(users)
    }
    const getCustomerInfo = (customerId) => {
        console.log(customerId)
        const data = {'token': token, 'customerId': customerId}
        var config = {
            method: 'post',
            url: "api/get_customer_users_and_drones",
            data: data
        };
        axios(config).then(response => {
            // console.log(response.data)
            setDronesAndUsers(response.data.drones, response.data.users)
        }).catch(error => {
            console.log('error', error)
        })
    }
    useEffect(() => {
        setNewUserName('');
        setNewUserPassword('');
        setShowForm(false);
        if(props.item){
            switch(props.itemType){
                case 'customer':
                    getCustomerInfo(props.item.customerId)
                    setCustomer(props.item)
                    if(props.item.access){
                      setClearances({
                        ['pcdDownload']: props.item.access.includes(Access.DOWNLOAD_PCD.number),
                        ['admin']: props.item.access.includes(Access.ADMIN.number),
                        ['flightPathDownload']: props.item.access.includes(Access.DOWNLOAD_FLIGHT_PATH.number),
                        ['mergeStandData']: props.item.access.includes(Access.MERGE_STAND_DATA.number),
                        ['addToStand']: props.item.access.includes(Access.ADD_TO_STAND.number),
                        ['georeferencedDataDownload']: props.item.access.includes(Access.DOWNLOAD_GEOREFERENCED_DATA.number),
                      });
                      setCurrentClearances(getAccessStringFromClearance({
                        ['pcdDownload']: props.item.access.includes(Access.DOWNLOAD_PCD.number),
                        ['admin']: props.item.access.includes(Access.ADMIN.number),
                        ['flightPathDownload']: props.item.access.includes(Access.DOWNLOAD_FLIGHT_PATH.number),
                        ['mergeStandData']: props.item.access.includes(Access.MERGE_STAND_DATA.number),
                        ['addToStand']: props.item.access.includes(Access.ADD_TO_STAND.number),
                        ['georeferencedDataDownload']: props.item.access.includes(Access.DOWNLOAD_GEOREFERENCED_DATA.number),
                      }))
                    } else {
                      setClearances({});
                      setCurrentClearances(getAccessStringFromClearance({}))
                    }
                    break
                case 'drone':
                    setView(<></>)
                    break
                default: 
                    setView(null)
            }
        }
    }, [props.item])
    
    useEffect(()=> {
        console.log(users)
        if(customer && customer.customerId !== '' && drones !== [] && users !== []){
            const droneList = drones.map(item => {
                return (
                    <div style={{display: "flex", flexDirection: "row" }}>

                        <ListGroup.Item
                            active={false}
                            eventKey={item._id} 
                            key={item._id} 
                        >
                            {item.name} - {item.status}
                        </ListGroup.Item>
                        <div hidden={!editUsers}>
                            <CloseButton onClick={() => {console.log('delete item:', item.name)}} />
                        </div>
                    </div>)
            })
            // console.log(droneList)
            const userList = users.map(item => {
                return (
                    <div style={{display: "flex", flexDirection: "row" }}>

                        <ListGroup.Item
                            active={false}
                            eventKey={item._id} 
                            key={item._id} 
                        >
                            {item.name}
                        </ListGroup.Item>
                        <div hidden={!editUsers}>
                            <CloseButton onClick={() => {console.log('delete item:', item.name)}} />
                        </div>
                    </div>)
            })
            console.log(userList)

        }
    }, [drones, users, customer])

    const handleAddUser = (event) => {
        event.preventDefault();
        if (newUserName.trim() && newUserPassword.trim()) {
            addNewUser()
            setNewUserName('');
            setNewUserPassword('');
            // setShowForm(false);
            getCustomerInfo(customer.customerId) // Hide form after adding user
        }
      };
    
      const handleShowForm = () => {
        setShowForm(!showForm);
      };
    
      const generatePassword = () => {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
        let password = '';
        for (let i = 0; i < 16; i++) {
          password += charset[Math.floor(Math.random() * charset.length)];
        }
        setNewUserPassword(password);
      };
      const addNewUser = () => {
            if(props.item && newUserName !== "" && newUserPassword !== ""){
            const data = {
                'token': token, 
                "user": {
                    "firstname": "",
                    "lastname": "",
                    "company": props.item.name ? props.item.name : "",
                    "customerId": props.item.customerId,
                    "email": newUserName,
                    "hp": newUserPassword
                    }
                }
            var config = {
                method: 'post',
                url: "api/insert_new_user",
                data: data
            };
            axios(config).then(response => {
                console.log(response.data)
            }).catch(error => {
                console.log('error', error)
            })
        }
      }
    
    const handleRemoveUser = (user) => {
        setUserToRemove(user);
        setShowModal(true);
    };

    const confirmRemoveUser = () => {
        console.log("removing, ",{userToRemove})
        if(userToRemove){
            const data = {
                'token': token, 
                "id": userToRemove._id
                    
                }
            var config = {
                method: 'post',
                url: "api/remove_user",
                data: data
            };
            axios(config).then(response => {
                console.log(response.data)
                if(response.data._id){
                    setusers(users.filter((user) => user !== userToRemove));
                }
            }).catch(error => {
                console.log('error', error)
            })
        }
        // setUsers(users.filter((user) => user !== userToRemove));
        setUserToRemove(null);
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

   
    function getAccessNumbersFromClearance(clearance) {
      const result = [];
      // Iterate over each entry in Access
      for (const key in Access) {
          if (Access.hasOwnProperty(key)) {
              const accessEntry = Access[key];
  
              // Check if the string in Access exists in clearance and if it's checked
              if (clearance[accessEntry.string] === true) {
                  result.push(accessEntry.number);
              }
          }
      }
  
      return result;
  }
  function getAccessStringFromClearance(clearance) {
    const result = [];
    // Iterate over each entry in Access
    for (const key in Access) {
        if (Access.hasOwnProperty(key)) {
            const accessEntry = Access[key];

            // Check if the string in Access exists in clearance and if it's checked
            if (clearance[accessEntry.string] === true) {
                result.push(accessEntry.string+", ");
            }
        }
    }

    return result;
}
  const handleClearanceChange = (e) => {
    const { name, checked } = e.target;
    setClearances((prevClearances) => ({
      ...prevClearances,
      [name]: checked,
    }));
   
  };

  const handleSaveClearance = () => {
    // Handle the saving of clearance settings here
    console.log('Clearance settings saved:', clearances);
    const access = getAccessNumbersFromClearance(clearances)
    // console.log(access)
    // console.log(customer)
    const data = {
      'token': token, 
      "customer": {
        '_id': customer._id,
        'access': access
      }         
    }
    var config = {
        method: 'post',
        url: "api/update_customer",
        data: data
    };
    axios(config).then(response => {
        console.log(response.data)
        if(response.data._id){
            setusers(users.filter((user) => user !== userToRemove));
        }
    }).catch(error => {
        console.log('error', error)
    })
    setCurrentClearances(getAccessStringFromClearance(clearances))
    // props.setUpdate(props.update+1)
    // getCustomerInfo(props.item.customerId)
    // Hide the form after saving
    setEditClearanceMode(false);
  };
    return (
        <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
    >
      <Card
        bg={'dark'}
        key={'AdminSelectedItem'}
        text={'white'}
        style={{ minWidth: '95%', height: '90%', margin: '1em', border: '3px outset #0f5132' }}
        className="mb-3"
      >
        <Card.Header style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: '1em' }}>
          {props.item && props.item.name ? props.item.name : props.type + ' info'}
        </Card.Header>
        <Card.Body>
          {props.item && (
            <Container>
              <Row className="mt-0" style={{ paddingTop: '0!important' }}>
                <Col md={6}>
                  <p>
                    <strong>Customer ID:</strong> {props.item.customerId}
                  </p>
                  <p>
                    <strong>Clearances:</strong> {currentClearances}
                  </p>
                  {!editClearanceMode && (
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => setEditClearanceMode(true)}
                      className="df-button"
                    >
                      Edit Clearance
                    </Button>
                  )}
                  {editClearanceMode && (
                    <Form className="mt-4">
                      <Form.Check
                        type="checkbox"
                        label="PCD Download"
                        name="pcdDownload"
                        checked={clearances['pcdDownload']}
                        onChange={handleClearanceChange}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Flight Path Download"
                        name="flightPathDownload"
                        checked={clearances['flightPathDownload']}
                        onChange={handleClearanceChange}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Merge Stand Data"
                        name="mergeStandData"
                        checked={clearances['mergeStandData']}
                        onChange={handleClearanceChange}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Add To Stand"
                        name="addToStand"
                        checked={clearances['addToStand']}
                        onChange={handleClearanceChange}
                      />
                       <Form.Check
                        type="checkbox"
                        label="Download georeferenced data"
                        name="georeferencedDataDownload"
                        checked={clearances['georeferencedDataDownload']}
                        onChange={handleClearanceChange}
                      />
                      <Button
                        variant="primary"
                        onClick={handleSaveClearance}
                        className="df-button mt-2"
                      >
                        Save
                      </Button>
                    </Form>
                  )}
                </Col>
              </Row>
              <Row className="mt-0">
                <Col md={6}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h2>Users</h2>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => setEditMode(!editMode)}
                      className="df-button"
                    >
                      {editMode ? 'Done' : 'Edit Users'}
                    </Button>
                  </div>
                  <ListGroup>
                    {users.map((user, index) => (
                      <ListGroup.Item key={index} className="user-list-item">
                        {user.email}
                        {editMode && (
                          <Button
                            className="remove-button"
                            variant="danger"
                            size="sm"
                            onClick={() => props.handleRemoveUser(user)}
                          >
                            X
                          </Button>
                        )}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
              <Row className="mt-0">
                <Col md={6}>
                  <Button variant="primary" onClick={() => setShowForm(!showForm)} className="df-button">
                    {showForm ? 'Cancel' : 'Add new user'}
                  </Button>
                  {showForm && (
                    <Form onSubmit={props.handleAddUser} className="mt-4">
                      <Form.Group controlId="formUserName">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={newUserName}
                          onChange={(e) => setNewUserName(e.target.value)}
                          placeholder="Enter user name"
                        />
                      </Form.Group>
                      <Form.Group controlId="formUserPassword" className="mt-2">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="text"
                          value={newUserPassword}
                          onChange={(e) => setNewUserPassword(e.target.value)}
                          placeholder="Enter password"
                        />
                        <Button variant="secondary" onClick={props.generatePassword} className="df-button">
                          Generate Strong Password
                        </Button>
                      </Form.Group>
                      <Button
                        disabled={newUserName === '' || newUserPassword === ''}
                        variant="primary"
                        type="submit"
                        className="df-button"
                      >
                        Add User
                      </Button>
                    </Form>
                  )}
                </Col>
              </Row>
              <Modal show={showModal} onHide={props.handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to remove the selected user?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={props.handleCloseModal}>
                    Cancel
                  </Button>
                  <Button variant="danger" onClick={props.confirmRemoveUser}>
                    Remove
                  </Button>
                </Modal.Footer>
              </Modal>
            </Container>
          )}
        </Card.Body>
      </Card>
    </div>
    )
}

SelectedCustomerInfo.propTypes = {
    item: PropTypes.object.isRequired,
    itemType: PropTypes.string.isRequired,
    update: PropTypes.number.isRequired,
    setUpdate: PropTypes.func.isRequired,
}