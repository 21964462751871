import React, {useState,useEffect}  from 'react';
import axios from 'axios';
import { Button, Tab, Tabs } from 'react-bootstrap'

import useToken from '../App/useToken';
import GeneralView from './GeneralView/GeneralView';
import { NewDroneForm } from './GeneralView/SelectedInfoView/DronesView/newDroneForm';
import { NewCustomerForm } from './GeneralView/SelectedInfoView/CustomersView/newCustomerForm';
import UpdateMeasurement from './Measurement/updateMeasurement';
import PropTypes from 'prop-types'

export default function AdminView(props) {
    const {token} = useToken()
    const [key, setKey] = useState('customers');
    // const [selectedCustomer, setSelectedCustomer] = useState(null)
    // const newSelectedCustomer = (customer) => {
    //     setSelectedCustomer(customer)
    // }
    const [customers, setCustomers] = useState([])
    const [drones, setDrones] = useState([])
    const [update, setUpdate] = useState(0)
    const [selectedView, setSelectedView] = useState('customers')
    const selectView = (view) => {
        setSelectedView(view)
    }
    useEffect(()=> {
        var formData = new FormData();
        formData.append('token', token);
        var config = {
            method: 'post',
            url: "api/get_customers_and_drones",
            data: formData
        };
        axios(config).then(response => {
            // console.log(response.data)
            const customers = response.data.customers
            const drones = response.data.drones
            setCustomers(customers)
            setDrones(drones)
        }).catch(error => {
            console.log('error', error)
        })
    }, [selectedView, update])

    return (
        <div style={{
            height: "100%",
            width: "95%",
            justifySelf: "center",
            border: "1px solid black"
          }}>
            <div>
                <Button onClick={()=>{selectView('customers')}} title="Customer">Customers</Button>
                <Button onClick={()=>{selectView('drones')}}title="Drones">Drones</Button>
                <Button onClick={()=>{selectView('addNewCustomer')}}title="Add new drone">Add new customer</Button>
                <Button onClick={()=>{selectView('addNewDrone')}}title="Add new drone">Add new drone</Button>
                <Button onClick={()=>{selectView('updateMeasurement')}}title="Update measurement">Update measurement</Button>
            </div>
            <div hidden={selectedView !== 'customers'}>
                <GeneralView items={customers} type='customer' update={update} setUpdate={setUpdate}/>
            </div>
            <div hidden={selectedView !== 'drones'}>
                <GeneralView items={drones} type='drone' update={update} setUpdate={setUpdate}/>
            </div>
            <div hidden={selectedView !== 'addNewDrone'}>
                <NewDroneForm items={customers} />
            </div>
            <div hidden={selectedView !== 'addNewCustomer'}>
                <NewCustomerForm items={customers} />
            </div>
            <div hidden={selectedView !== 'updateMeasurement'}>
                <UpdateMeasurement bagData={props.bagData} items={customers} type={'bagData'} />
            </div>
            
        </div>
    )
}

AdminView.propTypes = {
    bagData: PropTypes.array.isRequired,
  }